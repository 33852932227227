import {plugin_registry, PluginBase} from "nk-plugin-registry"
import "../scss/archive.scss"


@plugin_registry.register('CollectionExtender')
class CollectionExtender extends PluginBase {
    constructor($node) {
        super($node);

        this.$toggleHeaders = $node.querySelectorAll('[data-js-select=toggle-head]');
    }

    loaded($node) {
        super.loaded($node);

        this._install_event_listeners();
    }

    _install_event_listeners() {

        this.$toggleHeaders.forEach(
            ($s) => $s.addEventListener('click', this._toggle_visibility($s))
        )
    }

    _toggle_visibility = ($toggleHeader) => (e) => {
        const event_info = $toggleHeader.parentElement.querySelector(
            '[data-js-select=hide-on-toggle]'
        )

        const $toggleChevron = $toggleHeader.querySelector("i");

        if (event_info.classList.contains("hidden")) {
            event_info.classList.remove("hidden");
            $toggleChevron.classList.remove("icon-chevron-down")
            $toggleChevron.classList.add("icon-chevron-up")
        } else {
            event_info.classList.add("hidden");
            $toggleChevron.classList.add("icon-chevron-down")
            $toggleChevron.classList.remove("icon-chevron-up")
        }
    }
}